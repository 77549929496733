import { Link } from 'gatsby';

const Links = () => {
  return (
    <div>
      <p>
        <Link to="/app/assistant/profile">/app/assistant/profile</Link>
        <br></br>
        <Link to="/app/admin/page1">/app/admin/page1</Link>
        <br></br>
        <Link to="/app/admin/page2">/app/admin/page2</Link>
        <br></br>
        <Link to="/app/assistant/board">/app/assistant/board</Link>
      </p>
    </div>
  );
};

export default Links;
